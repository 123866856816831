<template>
  <div class="mb-5 mr-5">
    <div class="flex my-8 flex-col space-y-4 sm:flex-row sm:space-y-0 sm:items-center sm:space-x-4">
      <img
        src="/content/images/gmc-icon.png"
        alt="Google Merchant Center"
        class="w-fit"
      />
      <div v-if="step.completed">
        Success! You have connected Merchant Center account
        <strong>{{ store.integration.content_api.account_id }}</strong>
      </div>
      <div v-else>
        Connect your Google Merchant center account used for this Google Ads account. Bidbrain will use this to fetch
        product data and optimize your Campaigns and product data.
      </div>
    </div>
    <p v-if="!step.completed">Sign in with Google to give Bidbrain access to your Google Merchant center data.</p>
    <content-api-integration-button
      v-if="!step.completed"
      @integration-finished="onIntegrationFinished"
      button-text="Connect Google Merchant center"
      class="my-8"
    />

    <div class="flex flex-wrap gap-3">
      <the-button
        @click.stop="$emit('updateStep', { step, index, incremental: -1 })"
        inner-class="w-40"
        secondary
      >
        Back
      </the-button>

      <the-button
        @click.stop="$emit('updateStep', { step, index })"
        :disabled="!step.completed"
        inner-class="w-40"
        primary
      >
        Next
      </the-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BidLink from '@/components/base/BidLink'
import IcLink from 'vue-material-design-icons/OpenInNew'
import IcCheck from 'vue-material-design-icons/Check'
import ContentApiIntegrationButton from '@/components/store/integration/ContentAPIIntegrationButton'

// TODO Read integration status and update buttons accordingly
export default {
  name: 'content-api',
  components: { ContentApiIntegrationButton, BidLink, IcLink, IcCheck },
  props: {
    step: {
      type: Object,
    },
    index: {
      type: Number,
    },
    checkIfLastStep: {
      type: Boolean,
    },
  },
  data() {
    return {
      isUpdating: false,
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      store: 'store/selectedStore',
    }),
  },
  methods: {
    onIntegrationFinished() {
      this.$gtm.trackEvent({
        event: 'onboarding_content_api',
        event_source: 'onboarding',
        project: 'console',
      })
      this.$emit('updateStep', { step: this.step, index: this.index })
    },
  },
}
</script>
