<template>
  <base-modal>
    <template #header>
      <h3 class="m-0">Select GMC account</h3>
      <div v-if="loading">
        <div
          class="flex justify-center items-end"
          style="min-height: 30px"
        >
          <bb-loader />
        </div>
        <span class="p2"> Loading Google Merchant center accounts </span>
      </div>
    </template>
    <template #default>
      <!-- // Min height set so modal wont clip picker dropdown -->
      <div style="min-height: 220px">
        Select GMC account to be integrated with <span class="font-bold">{{ store.name }}</span>
        <base-picker
          v-model="selectedAccount"
          :items="accounts"
          :item-label="item => item.label"
          :disabled="loading || accounts.length < 2"
          label="Available GMC accounts"
          input-id="content-account"
          input-name="content-account"
          class="w-full mt-2"
          hideFeedback
        />
        <base-alert
          v-if="accountMismatch"
          type="error"
          class="mt-2"
        >
          <template #content>
            <p class="p3">
              The email used during auth does not have access to the GMC account added in
              <strong>
                <router-link :to="{ name: 'store:settings:store' }">store settings</router-link> </strong
              >.
            </p>
            <p class="p3">
              Please use an email with access to the GMC account
              <strong>{{ store.gmc_account_id }}</strong
              >.
            </p>
            <the-button
              @click="mailCSM"
              primary
              small
              class="mt-2"
            >
              <template #default> Contact your success manager </template>
              <template #icon>
                <ic-chevron-right :size="14" />
              </template>
            </the-button>
          </template>
        </base-alert>
        <div
          v-if="!accountMismatch"
          @click="showAuthInfo = !showAuthInfo"
          class="p3 text-button text-bb-mid-grey"
        >
          Not seeing your account?
          <ic-question
            :size="14"
            class="text-bb-neon-purple ml-1"
          />
        </div>
        <base-alert
          v-if="showAuthInfo"
          class="mt-2"
        >
          <template #content>
            <p class="p3">The accounts listed above is tied to the email used previously during Content API auth.</p>
            <p class="p3">If you wish to change email used please contact support and they will reset this for you.</p>
            <the-button
              @click="mailCSM"
              primary
              small
              class="mt-2"
            >
              <template #default> Contact your success manager </template>
              <template #icon>
                <ic-chevron-right :size="14" />
              </template>
            </the-button>
          </template>
        </base-alert>
        <base-alert
          v-if="updateError"
          type="error"
          class="mt-2"
        >
          <template #content>
            <p>Could not integrate "{{ selectedAccount.name }}" with {{ store.name }}</p>
            <the-button
              @click="mailCSM"
              primary
              small
              class="mt-2"
            >
              <template #default> Contact your success manager </template>
              <template #icon>
                <ic-chevron-right :size="14" />
              </template>
            </the-button>
          </template>
        </base-alert>
      </div>
    </template>
    <template #footer>
      <div class="flex space-x-4 justify-end">
        <the-button
          @click="$emit('close')"
          :disabled="loading"
          secondary
        >
          Cancel
        </the-button>
        <the-button
          :disabled="loading || nothingSelected || accountMismatch"
          @click="handleSave"
          primary
        >
          Save
        </the-button>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as Sentry from '@sentry/vue'
import BasePicker from '@/components/input/base/BasePicker'
import IcQuestion from 'vue-material-design-icons/InformationOutline'
import IcAlert from 'vue-material-design-icons/AlertCircleOutline'
import BaseAlert from '@/components/alert/BaseAlert'
import IcChevronRight from 'vue-material-design-icons/ChevronRight'
import BbLoader from '@/components/loader/BBLoader'

const NO_SELECTION = { id: null, name: '', label: 'No account selected', url: null }

export default {
  name: 'content-api-account-modal',
  components: { BbLoader, BaseAlert, BasePicker, IcAlert, IcChevronRight, IcQuestion },
  props: {
    storeId: {
      type: Number,
      required: true,
    },
    onSave: {
      type: Function,
    },
  },
  data() {
    return {
      showAuthInfo: false,
      accountMismatch: false,
      loading: false,
      loadError: null,
      updateError: null,
      accounts: [],
      selectedAccount: NO_SELECTION,
    }
  },
  computed: {
    ...mapGetters({
      storeById: 'store/storeById',
    }),
    store() {
      return this.storeById(this.storeId)
    },
    nothingSelected() {
      return this.selectedAccount === NO_SELECTION
    },
  },
  watch: {
    store() {
      this.initModal()
    },
  },
  methods: {
    ...mapActions({
      loadStore: 'store/loadStoreDetails',
    }),
    async initModal() {
      await this.loadContentAPIAccounts()

      // If the store already have a gmc_account_id then it must match the account used in Content API
      // integration. Auto save if we have a match, show mismatch information if not.
      if (this.store.gmc_account_id && !this.loadError) {
        let matchingAccount = this.accounts.find(
          account => account.id === this.store.gmc_account_id || account.parent_id === this.store.gmc_account_id,
        )
        if (!!matchingAccount) {
          this.selectedAccount = matchingAccount
          this.handleSave()
        } else {
          this.accountMismatch = true
        }
      }
      // If the store does NOT have gmc_account_id then the user may choose any sub account.
    },
    loadContentAPIAccounts() {
      this.loading = true
      this.loadError = null

      return this.$http
        .get(`/store/${this.storeId}/integration/content-api/accounts`)
        .then(({ data: { data } }) => (this.accounts = this.accountDataToArrayTransformer(data)))
        .catch(e => {
          this.loadError = e
          Sentry.captureException(e)
        })
        .finally(() => (this.loading = false))
    },
    accountDataToArrayTransformer(data) {
      let list = []
      if (!data || data.length === 0) {
        list.push(NO_SELECTION)
        return list
      }

      const accountIds = Object.keys(data)
      let cleanUrl
      for (let i = 0; i < accountIds.length; i++)
        for (let j = 0; j < data[accountIds[i]].length; j++) {
          cleanUrl = (data[accountIds[i]][j].url || '').toLowerCase().replace('http://', '').replace('https://', '')
          list.push({
            ...data[accountIds[i]][j],
            id: Number.parseInt(data[accountIds[i]][j].id),
            parent_id: accountIds[i],
            label: `${data[accountIds[i]][j].name} ~ ${data[accountIds[i]][j].id} ~ ${cleanUrl}`,
          })
        }

      return [NO_SELECTION, ...list.sort((a, b) => (a.name > b.name ? 1 : -1))]
    },
    handleSave() {
      this.loading = true
      this.updateError = null

      const data = {
        gmc_sub_account_id: this.selectedAccount.id,
        gmc_account_id: this.selectedAccount.parent_id,
        gmc_sub_account_description: this.selectedAccount.name || this.selectedAccount.url,
      }
      this.$http
        .put(`/store/${this.storeId}/integration/content-api`, data)
        .then(_ => this.loadStore(this.storeId))
        .then(() => this.$emit('close'))
        .catch(e => {
          this.updateError = e
          Sentry.captureException(e)
        })
        .finally(() => (this.loading = false))
    },
    mailCSM() {
      window.open('mailto:maria.modig@speqta.com,alexander.facklam@speqta.com')
    },
  },
  beforeMount() {
    this.initModal()
  },
}
</script>

<style lang="scss" scoped>
.text-button {
  @apply flex items-center mt-1;
  transition: opacity ease-out 120ms;
  cursor: pointer;

  &:hover {
    @apply opacity-75;
  }
}
</style>
