<template>
  <google-btn
    @click="integrateContentAPI"
    :disabled="loadingStore || disabled"
    blue
    name="Signup with google"
  >
    <template> Sign in with Google </template>
  </google-btn>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ContentAPIAccountModal from '@/components/modals/ContentAPIAccountModal'
import googleConfig from '@/configs/google-client'
import Toast from '@/components/shared/Toast'
import * as Sentry from '@sentry/vue'
import GoogleBtn from '@/components/btn/GoogleBtn'

export default {
  name: 'content-api-integration-button',
  emits: ['integration-finished'],
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
    },
  },
  data() {
    return {
      disabled: false,
    }
  },
  components: {
    GoogleBtn,
  },
  computed: {
    ...mapGetters({
      store: 'store/selectedStore',
      isLoadingStore: 'store/isLoadingStore',
    }),
    loadingStore() {
      return this.isLoadingStore(this.store.id)
    },
    hasAccess() {
      return this.store.integration.content_api.has_token
    },
    fullyIntegrated() {
      return this.hasAccess && !!this.store.integration.content_api.account_id
    },
    fallbackButtonText() {
      if (this.hasAccess) return 'Select account'
      return 'Sign in with Google'
    },
    cardStatus() {
      switch (true) {
        case this.fullyIntegrated:
          return 'success'
        case this.hasAccess:
          return 'warn'
        default:
          return 'default'
      }
    },
  },
  methods: {
    ...mapActions({
      loadStore: 'store/loadStoreDetails',
    }),
    async integrateContentAPI() {
      if (this.hasAccess) {
        // Already have token, only list accounts
        this.showContentAPIAccountSelection()
      } else {
        // Get token to list accounts
        const success = await this.setupContentAPIAccess()
        if (!success) return
        await this.loadStore(this.store.id)
        this.showContentAPIAccountSelection()
      }
    },
    showContentAPIAccountSelection() {
      this.$modal.show(ContentAPIAccountModal, { storeId: this.store.id })
    },
    showErrorMessage() {
      this.$toast.error({
        component: Toast,
        props: {
          title: 'Error',
          message: 'Could not update integration, please contact support',
          type: 'error',
        },
      })
    },
    async setupContentAPIAccess() {
      this.disabled = true

      // Get g-auth instance
      let instance = await window.gapi.auth2.getAuthInstance()
      if (!instance) {
        Sentry.captureException('Cannot get gapi instance')
        this.showErrorMessage()
        this.disabled = false
        return false
      }

      // Ask for Content API access token
      let gRes
      try {
        gRes = await instance.currentUser.get().grantOfflineAccess({
          prompt: 'consent',
          response_type: 'code',
          scope: googleConfig.scopes.contentAPI,
        })
      } catch (e) {
        if (e.error !== 'popup_closed_by_user') {
          this.showErrorMessage()
          Sentry.captureException(e)
        }
        this.disabled = false
        return false
      }
      if (!gRes.code) {
        this.showErrorMessage()
        this.disabled = false
        Sentry.captureException('No Google Content API token returned', { tags: { response: gRes } })
        return false
      }

      // Send received token to backend
      let backendRes
      try {
        backendRes = await axios.post(`/store/${this.store.id}/integration/content-api`, { token: gRes.code })
        await this.loadStore(this.store.id)
      } catch (e) {
        this.showErrorMessage()
        Sentry.captureException('Cannot store Content API token', { tags: { response: backendRes } })
      }

      this.$emit('integration-finished')
      this.disabled = false
      return true
    },
  },
}
</script>
