<template>
  <div
    class="base-input-text__container"
    @click="$emit('click')"
  >
    <div class="flex justify-between">
      <label
        v-if="label"
        :for="inputId"
      >
        {{ label && required ? label.concat(' *') : label }}
      </label>
      <div v-if="!!$slots['info']">
        <slot name="info"></slot>
      </div>
    </div>

    <div
      class="base-input-text__input-wrapper"
      :class="inputWrapperClasses"
    >
      <div
        v-if="!!$slots['prefix']"
        class="base-input-text__affix-wrapper pl-4"
        :class="{ 'border-r pr-4': !!affixSeparator }"
      >
        <slot name="prefix" />
      </div>
      <input
        :type="inputType"
        :id="inputId"
        :name="inputName"
        :placeholder="placeholder"
        :value="value"
        :disabled="disabled"
        @input="e => $emit('input', e.target.value)"
        @keydown="emitKeyDown"
        @focus="handleInputFocus"
        @focusout="handleInputFocus"
        class="base-input-text__input px-4"
      />
      <div
        v-if="!!$slots['suffix']"
        class="base-input-text__affix-wrapper pr-4"
        :class="{ 'border-l pl-4': !!affixSeparator }"
      >
        <slot name="suffix" />
      </div>
      <div
        v-if="loading"
        class="base-input-text__loader-container"
      >
        <slot
          v-if="!!$slots['loader']"
          name="loader"
        />
        <div
          v-else
          class="flex justify-center items-center"
        >
          {{ loadingText }}
          <loader-ellipsis class="text-bb-neon-purple transform scale-50" />
        </div>
      </div>
    </div>
    <feedback-message
      v-if="!hideFeedback"
      :message="errorMessage"
    />
  </div>
</template>
<script>
import LoaderEllipsis from '@/components/loader/EllipsisLoader'
import FeedbackMessage from '@/components/base/FeedbackMessage'

export default {
  name: 'base-input-text',
  components: { FeedbackMessage, LoaderEllipsis },
  props: {
    inputId: {
      type: String,
      required: true,
    },
    inputName: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    value: {
      type: [Object, Array, String, Number, null],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    success: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    affixSeparator: {
      type: Boolean,
      default: false,
    },
    inputType: {
      type: String,
      default: 'text',
    },
    hideFeedback: {
      type: Boolean,
      default: false,
    },
    loadingText: {
      type: String,
      default: 'Loading',
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['input', 'focus', 'focusout'],
  data() {
    return {
      hasFocus: false,
    }
  },
  computed: {
    inputWrapperClasses() {
      if (this.disabled) {
        return { disabled: true }
      }
      return {
        focus: this.hasFocus,
        success: this.success,
        error: this.error || !!this.errorMessage,
      }
    },
  },
  methods: {
    handleInputFocus(e) {
      this.hasFocus = e.type === 'focus'
      this.$emit(e.type, e)
    },
    emitKeyDown(e) {
      this.$emit('keydown', e)
    },
  },
}
</script>
